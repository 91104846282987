import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { FakeDbService } from "@fake-db/fake-db.service";

import "hammerjs";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { TranslateModule } from "@ngx-translate/core";
import { ContextMenuModule } from "@ctrl/ngx-rightclick";

import { CoreModule } from "@core/core.module";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";
import { CardSnippetModule } from "@core/components/card-snippet/card-snippet.module";

import { coreConfig } from "app/app-config";
import { AuthGuard } from "app/auth/helpers/auth.guards";
import { fakeBackendProvider } from "app/auth/helpers"; // used to create fake backend
import { JwtInterceptor, ErrorInterceptor } from "app/auth/helpers";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";

import { ContextMenuComponent } from "app/main/extensions/context-menu/context-menu.component";
import { AnimatedCustomContextMenuComponent } from "./main/extensions/context-menu/custom-context-menu/animated-custom-context-menu/animated-custom-context-menu.component";
import { BasicCustomContextMenuComponent } from "./main/extensions/context-menu/custom-context-menu/basic-custom-context-menu/basic-custom-context-menu.component";
import { SubMenuCustomContextMenuComponent } from "./main/extensions/context-menu/custom-context-menu/sub-menu-custom-context-menu/sub-menu-custom-context-menu.component";

const appRoutes: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("./main/dashboard/dashboard.module").then(m => m.DashboardModule),
  },
  // {
  // 	path: "apps",
  // 	loadChildren: () =>
  // 		import("./main/apps/apps.module").then((m) => m.AppsModule),
  // 	canActivate: [AuthGuard],
  // },
  {
    path: "pages",
    loadChildren: () =>
      import("./main/pages/pages.module").then(m => m.PagesModule),
  },
  {
    path: "ui",
    loadChildren: () => import("./main/ui/ui.module").then(m => m.UIModule),
    canActivate: [AuthGuard],
  },
  // {
  // 	path: "components",
  // 	loadChildren: () =>
  // 		import("./main/components/components.module").then(
  // 			(m) => m.ComponentsModule
  // 		),
  // 	canActivate: [AuthGuard],
  // },
  // {
  // 	path: "extensions",
  // 	loadChildren: () =>
  // 		import("./main/extensions/extensions.module").then(
  // 			(m) => m.ExtensionsModule
  // 		),
  // 	canActivate: [AuthGuard],
  // },
  // {
  // 	path: "forms",
  // 	loadChildren: () =>
  // 		import("./main/forms/forms.module").then((m) => m.FormsModule),
  // 	canActivate: [AuthGuard],
  // },
  // {
  // 	path: "tables",
  // 	loadChildren: () =>
  // 		import("./main/tables/tables.module").then((m) => m.TablesModule),
  // 	canActivate: [AuthGuard],
  // },
  // {
  // 	path: "charts-and-maps",
  // 	loadChildren: () =>
  // 		import("./main/charts-and-maps/charts-and-maps.module").then(
  // 			(m) => m.ChartsAndMapsModule
  // 		),
  // 	canActivate: [AuthGuard],
  // },
  {
    path: "profile",
    loadChildren: () =>
      import("./main/profile/profile.module").then(m => m.ProfileModule),
    canActivate: [AuthGuard],
  },

  {
    path: "",
    redirectTo: "/kyc",
    pathMatch: "full",
  },
  {
    path: "groups",
    loadChildren: () =>
      import("./main/Groups/group.module").then(m => m.GroupModule),
    canActivate: [AuthGuard],
  },
  {
    path: "transaction-history",
    loadChildren: () =>
      import("./main/transaction-history/transaction-history.module").then(
        m => m.TransactionHistoryModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "transaction-reversal",
    loadChildren: () =>
      import("./main/transaction-reversal/transaction-reversal.module").then(
        m => m.TransactionReversalModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "pending-transactions",
    loadChildren: () =>
      import("./main/pending-transaction/pending-transaction.module").then(
        m => m.PendingTransactionModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "transaction-search",
    loadChildren: () =>
      import("./main/transaction-search/transaction-search.module").then(
        m => m.TransactionSearchModule
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "mobiles",
    loadChildren: () =>
      import("./main/mobiles/mobiles.module").then(m => m.MobilesModule),
    canActivate: [AuthGuard],
  },
  {
    path: "third-party-assignment",
    loadChildren: () =>
      import("./main/third-party-assignment/third-party.module").then(
        m => m.ThirdPartyModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "services",
    loadChildren: () =>
      import("./main/services/services.module").then(m => m.ServiceModule),
    canActivate: [AuthGuard],
  },
  {
    path: "bundles",
    loadChildren: () =>
      import("./main/bundles/bundle.module").then(m => m.BundleModule),
    canActivate: [AuthGuard],
  },
  {
    path: "maker",
    loadChildren: () =>
      import("./main/maker/maker.module").then(m => m.MakerModule),
    canActivate: [AuthGuard],
  },
  {
    path: "checker",
    loadChildren: () =>
      import("./main/checker/checker.module").then(m => m.CheckerModule),
    canActivate: [AuthGuard],
  },
  {
    path: "maker-checker-groups",
    loadChildren: () =>
      import("./main/maker-checker-groups/maker-checker-groups.module").then(
        m => m.MakerCheckerGroupModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "kyc",
    loadChildren: () => import("./main/kyc/kyc.module").then(m => m.KycModule),
    canActivate: [AuthGuard],
  },
  {
    path: "user-preferences",
    loadChildren: () =>
      import("./main/user-preferences/user-preferences.module").then(
        m => m.UserPreferencesModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "user-wallets",
    loadChildren: () =>
      import("./main/user-wallets/user-wallets.module").then(
        m => m.UserWalletsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "jazzcash-id",
    loadChildren: () =>
      import("./main/jazzcash-id/jazzcash-id.module").then(
        m => m.JazzCashIdModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "user-bank-details",
    loadChildren: () =>
      import("./main/user-bank-details/user-bank-details.module").then(
        m => m.UserBankDetailsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "transaction-settlement",
    loadChildren: () =>
      import(
        "./main/transaction-settlement/transaction-settlement.module"
      ).then(m => m.TransactionSettlementModule),
    canActivate: [AuthGuard],
  },
  {
    path: "all-transaction-settlement",
    loadChildren: () =>
      import(
        "./main/all-transaction-settlement/all-transaction-settlement.module"
      ).then(m => m.AllTransactionSettlementModule),
    canActivate: [AuthGuard],
  },
  {
    path: "billing-process",
    loadChildren: () =>
      import("./main/billing-process/billing-process.module").then(
        m => m.BillingProcessModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    redirectTo: "/pages/miscellaneous/error", //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [
    AppComponent,
    ContextMenuComponent,
    BasicCustomContextMenuComponent,
    AnimatedCustomContextMenuComponent,
    SubMenuCustomContextMenuComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true,
    }),
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
    }),
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),
    ContextMenuModule,
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CardSnippetModule,
    LayoutModule,
    ContentHeaderModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // ! IMPORTANT: Provider used to create fake backend, comment while using real API
    fakeBackendProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
