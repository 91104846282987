import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  {
    id: "general",
    type: "section",
    title: "Main Menu",
    children: [
      {
        id: "groups",
        type: "item",
        title: "Groups",
        icon: "users",
        url: "/groups",
      },

      {
        id: "kyc",
        type: "item",
        title: "Kyc",
        icon: "users",
        url: "/kyc",
      },
      {
        id: "mobiles",
        type: "item",
        title: "Mobiles",
        icon: "phone",
        url: "/mobiles",
      },
      {
        id: "third-party",
        type: "item",
        title: "Third Party Assignment",
        icon: "users",
        url: "/third-party-assignment",
      },

      {
        id: "services",
        type: "item",
        title: "Services",
        icon: "archive",
        url: "/services",
      },
      {
        id: "bundles",
        type: "item",
        title: "Bundles",
        icon: "package",
        url: "/bundles",
      },
      {
        id: "transaction-history",
        type: "item",
        title: "Transaction History",
        icon: "calendar",
        url: "/transaction-history",
      },

      {
        id: "transaction-reversal",
        type: "item",
        title: "Transaction Reversal",
        icon: "rotate-ccw",
        url: "/transaction-reversal",
      },
      {
        id: "transaction-search",
        type: "item",
        title: "Transaction Search",
        icon: "search",
        url: "/transaction-search",
      },
      {
        id: "pending-transactions",
        type: "item",
        title: "Pending Transactions",
        icon: "calendar",
        url: "/pending-transactions",
      },
      {
        id: "billing-process",
        type: "item",
        title: "Billing Process",
        icon: "calendar",
        url: "/billing-process",
      },
      {
        id: "maker",
        type: "item",
        title: "Maker",
        icon: "check-circle",
        url: "/maker",
      },
      {
        id: "checker",
        type: "item",
        title: "Checker",
        icon: "check-circle",
        url: "/checker",
      },
      {
        id: "maker-checker-groups",
        type: "item",
        title: "Maker Checker Groups",
        icon: "check-circle",
        url: "/maker-checker-groups",
      },
      {
        id: "user-preferences",
        type: "item",
        title: "User Preferences",
        icon: "user",
        url: "/user-preferences",
      },
      {
        id: "user-wallets",
        type: "item",
        title: "User Wallets",
        icon: "user",
        url: "/user-wallets",
      },

      {
        id: "jazzcash-id",
        type: "item",
        title: "JazzCash ID",
        icon: "credit-card",
        url: "/jazzcash-id",
      },
      {
        id: "user-bank-details",
        type: "item",
        title: "App User Bank Details",
        icon: "dollar-sign",
        url: "/user-bank-details",
      },
      // {
      //   id: "transaction-settlement",
      //   type: "item",
      //   title: "Transaction Settlement",
      //   icon: "calendar",
      //   url: "/transaction-settlement",
      // },
      // {
      //   id: "all-transaction-settlement",
      //   type: "item",
      //   title: "All Transaction Settlement",
      //   icon: "calendar",
      //   url: "/all-transaction-settlement",
      // },
    ],
  },
];
